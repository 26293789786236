<template>
<DenseContainer>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-title>Aktuell keine Aktion!</v-card-title>
                <v-card-text>
                    Die Wunschbaumaktion ist aktuell nicht verfügbar. Bitte kommen Sie zu einem späteren Zeitpunkt wieder. Wir werden Sie informieren, sobald die Wunschbaumaktion wieder verfügbar ist!
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</DenseContainer>
</template>
<script>
export default {
    mounted() {
        if(this.$store.state.config.campaign.started === true) {
            this.$router.push("/wishes");
        }
    }
}
</script>